import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hoppas att ni hade en trevlig alla hjärtansdag. Det var bra med free spins erbjudande tillgängliga under gårdagen och andra trevliga erbjudande. Bland annat så valde Betsson att ge bort en miljon kronor till en svensk spelare, dom spelade även in en video när Jan Arne Riise ringer upp spelaren och informera spelaren om vinsten.`}</p>
    <p>{`Kommentera gärna om det kommer upp några nya erbjudande som vi inte har skrivit om nedan.`}</p>
    <h3>{`Unibet`}</h3>
    <p>{`Hos Unibet idag så får alla kunder 8 free spins på Gonzo Quest igenom deras Vinter OS Kalender. Vi kan tacka Johan Olsson som tog silver och Daniel Richardsson som tog brons för dessa free spins då Unibet ger bort free spins när Sverige tar medaljer i OS.`}</p>
    <p>{`Man får även 1 free spins på South Park idag då Unibet även ger free spins efter hur många mål som det svenska hockeylandslaget för herrar gör. Då det blev ett mål igår emot Schweiz så hittar vi därför ett free spins på South Park.`}</p>
    <p>{`8 free spins på Gonzo Quest och 1 free spin på South Park – Spela nu!`}</p>
    <h3>{`Paf – medaljbonus`}</h3>
    <p>{`För varje svenskmedalj under OS så ger PAF ut free spins och andra trevliga bonusar. Med gårdagens framgång med två medaljer så kommer vi att få free spins hos PAF idag.
Har man inget konto sedan tidigare så kommer man även att ta emot 20 free spins på Starburst om ny kund.`}</p>
    <p>{`Medalj free spins hos PAF – Klicka här`}</p>
    <h3>{`Vinnarum – free spins`}</h3>
    <p>{`Alla kunder får idag 5 free spins på Lucky Angler hos Vinnarum. Man kan även få mera free spins igenom att göra en insättning på minst 500kr för att få 50 extra free spins i samma spel. Detta erbjudande är tillgängligt mellan den 14 – 16 februari.`}</p>
    <p>{`5 free spins på Lucky Angler – Spela nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      